#contact {
    header {
        position: static;
        padding: .95rem 0;
    }

    .contact {
        padding: 3rem 0 8rem 0;

        &__heading {
            text-align: center;
            margin-bottom: 5rem;
        }

        &__flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5rem;
        }

        &__text {
            font-size: .875rem;
        }

        &__steps {
            display: flex;
            gap: .8rem;
        }

        &__step {
            background-color: $gray;
            font-size: .875rem;
            font-weight: 600;
            padding: .5rem 0;
            width: 6.5rem;
            border-radius: 5px;
            text-align: center;

            &--red {
                background-color: $main;
            }

            &--lg {
                background-color: $light-gray;
            }
        }

        &__arrow {
            width: .8rem;
            transform: translateX(.1rem);
        }

        .wrapper {
            max-width: 900px;
        }
    }

    .form {
        width: 100%;

        &__parts {
            display: flex;
            margin: 2rem 0;

            &:not(:nth-child(5)) {
                padding-bottom: 2rem;
                border-bottom: 1px solid $gray;
            }
        }

        &__text {
            width: 40%;
            font-weight: 600;
            margin-top: 1rem;

            &--required {
                font-size: .75rem;
                background-color: $main;
                border-radius: 5px;
                padding: .2rem .5rem;
                margin-right: .5rem;
            }

            &--pp {
                margin-top: 0;
            }
        }

        &__input {
            width: 60%;
            color: $white;
            padding: 1rem 1.5rem;
        }

        textarea {
            min-height: 9.375rem;
        }

        &__flex {
            display: flex;
        }

        &__check {
            width: 1.5rem;
            margin-right: .5rem;
            cursor: pointer;
        }

        &__link {
            text-decoration: underline;

            &:hover {
                opacity: .5;
            }
        }

        &__btn {
            font-size: 1.25rem;
            font-weight: 600;
            color: $main;
            background-color: $white;
            border-radius: 100px;
            border: 3px solid $white;
            box-shadow: 3px 7px 15px 0px #00000081;
            display: block;
            padding: 1rem 8rem;
            margin: 4rem auto 0 auto;
            transition: .3s;

            &:hover {
                border: 3px solid $main;
            }
        }
    }

    .confirm {
        display: none;
        position: fixed;
        top: 5vh;
        left: 0;
        z-index: 20;
        width: 100vw;

        &.open {
            display: block;
        }

        .wrapper {
            max-width: 1170px;
        }

        &__popup {
            background-color: $gray;
            padding: 3rem 6rem 4rem 6rem;
            border-radius: 30px;
            height: 90vh;
        }

        .contact__flex {
            margin-bottom: 2.5rem;
        }

        &__scroll {
            height: 55vh;
            overflow: scroll;
        }

        &__parts {
            display: flex;
            padding: 2rem 0;

            &:not(:nth-child(5)) {
                border-bottom: 1px solid $light-gray;
            }
        }

        .form__text {
            width: 35%;
            margin-top: 0;
        }

        &__text {
            width: 65%;
            color: $white;
        }

        textarea {
            resize: none;
        }

        &__flex {
            display: flex;
            justify-content: center;
            margin-top: 3rem;
            gap: 1rem;
        }

        &__back,
        &__send {
            width: 45%;
            font-size: 1.25rem;
            font-weight: 600;
            border-radius: 100px;
            padding: 1rem;
            transition: .3s;
        }

        &__back {
            display: block;
            color: $white;
            border: 3px solid $black;
            background-color: $black;

            &:hover {
                border: 3px solid $white;
            }
        }

        &__send {
            color: $main;
            background-color: $white;
            border: 3px solid $white;

            &:hover {
                border: 3px solid $main;
            }
        }

        &__bg {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 19;
            width: 100vw;
            height: 100vh;
            background-color: #0000007e;
            transition: .3s;

            &.open {
                display: block;
            }
        }
    }
}

@media (max-width: 430px) {
    #contact {
        .contact {
            padding: 3rem 0 6rem 0;

            .wrapper {
                max-width: 90vw;
            }

            &__heading {
                margin-bottom: 2.5rem;
            }

            &__flex {
                flex-direction: column;
                align-items: start;
                gap: 1.5rem;
                margin-bottom: 2rem;
            }

            &__steps {
                order: 1;
                font-size: .75rem;
                gap: .5rem;
                justify-content: center;
                width: 100%;
            }

            &__step {
                width: 6.2rem;
            }

            &__arrow {
                width: .5rem;
            }

            &__text {
                order: 2;
                text-align: left;
            }

            .form {
                &__parts {
                    flex-direction: column;
                    margin: 0;
                }

                &__text {
                    width: 100%;
                    margin-bottom: 1rem;
                    padding-top: .5rem;

                    &--pp {
                        padding-top: 2rem;
                    }
                }

                &__input {
                    width: 100%;
                    padding: .8rem 1rem;
                }

                &__btn {
                    font-size: 1.125rem;
                    padding: 1rem 0;
                    width: 100%;
                }
            }
        }

        .confirm {
            top: 2.5vh;

            &__popup {
                padding: 5vh 1rem;
                height: auto;
            }

            .contact {
                &__flex {
                    margin-bottom: 0;
                }

                &__text {
                    order: 1;
                }

                &__steps {
                    order: 2;
                }
            }

            .form {
                &__text {
                    margin-bottom: .5rem;
                }
            }

            &__scroll {
                margin: 1rem 0;
                height: 45vh;
            }

            &__parts {
                padding: 1.2rem 0;
                flex-direction: column;
            }

            &__text {
                width: 100%;
            }

            &__flex {
                margin-top: 0;
                flex-direction: column;
            }

            &__back, &__send {
                width: 100%;
                font-size: 1.125rem;
                padding: 2vh 0;
            }
        }
    }
}