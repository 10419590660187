.swiper {
    max-width: 1170px;

    .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 430px) {
    .swiper-container {
        position: relative;

        .swiper-pagination-bullet {
            background-color: $white;
            opacity: 1;
            width: 10px;
            height: 10px;
            margin: 0 15px!important;
            transition: .3s;
        }
    
        .swiper-pagination-bullet-active {
            background-color: $main;
            transition: .3s;
        }
    
        .swiper-button-prev::after,
        .swiper-button-next::after {
            color: $white;
            font-size: 1.5rem !important;
        }

        .swiper-pagination {
            position: absolute;
            bottom: -3.5rem !important;
        }
        
        .swiper-button-prev, .swiper-button-next {
            position: absolute;
            top: auto;
            bottom: -4.2rem !important;
        }

        .swiper-button-prev {
            left: 15vw;
        }

        .swiper-button-next {
            right: 15vw;
        }
    }
}