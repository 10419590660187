#download {
    background-color: $gray;
    padding: 6rem 0;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            margin-bottom: 1.5rem;
        }

        .box {
            position: relative;
            display: block;
            color: $black;
            background-color: $white;
            border: 3px solid $white;
            border-radius: 20px;
            font-weight: 600;
            line-height: 1;
            padding: 2rem;
            width: 45rem;
            box-shadow: 3px 7px 15px 0px #00000081;
            overflow: hidden;

            &__flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__flex--left {
                display: flex;
                align-items: center;
                gap: 2rem;
            }

            &__img {
                height: 2.5rem;
                width: auto;
            }

            &__english {
                color: $main;
                font-family: $bebas;
                font-size: 2rem;
                font-weight: normal;
            }

            &__dlicon {
                width: 2.5rem;
            }

            &:hover {
                border: 3px solid $main;
            }

            &__preparing {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #00000081;
                display: grid;
                place-content: center;
            }

            &__pptext {
                font-family: $bebas;
                font-weight: normal;
                font-size: 1.8rem;
                letter-spacing: .05rem;
                color: $white;
                background-color: $main;
                border: 3px solid $white;
                padding: .8rem 2.5rem .5rem 2.5rem;
            }

            &__ppborder {
                border: 3px solid #00000081;
                box-shadow: none;
                
                &:hover {
                    border: 3px solid #00000081;
                }
            }
        }

        .box + .box {
            margin-top: 1rem;
        }
    }
}

@media (max-width: 430px) {
    #download {
        padding: 5rem 0;

        .wrapper {
            flex-direction: column;

            .heading {
                margin-bottom: 1.5rem;
            }

            .download {
                &__text {
                    text-align: center;
                    font-size: .875rem;
                    font-weight: 500;
                    margin-bottom: 2rem;
                }
            }

            .box {
                padding: 1.5rem;
                width: 90vw;
                text-align: center;

                &__flex {
                    display: block;
                }

                &__flex--left {
                    flex-direction: column;
                    gap: 1rem;
                }
    
                &__img {
                    height: 2.5rem;
                    width: auto;
                }
    
                &__english {
                    color: $main;
                    font-family: $bebas;
                    font-size: 2rem;
                    font-weight: normal;
                }

                &__text {
                    line-height: 1.3;
                }
    
                &__dlicon {
                    display: none;
                }
            }
        }
    }
}