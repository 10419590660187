.popup {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #161616af;
    display: none;

    &.open {
        display: grid;
        place-content: center;
    }

    &__img {
        max-width: 800px;
        display: none;

        &.open {
            display: block;
        }
    }

    &__btn {
        position: fixed;
        z-index: 21;
        top: 2.5rem;
        right: 2.5rem;
    }

    &__back {
        position: relative;
        display: inline-block;
        width: 2.8rem;
        height: 2.8rem;

        &::before {
            content: '';
            position: absolute;
            top: 1.25rem;
            right: 0;
            background-color: $main;
            width: 2.8rem;
            height: 5px;
            transform: rotate(45deg);
        }

        &::after {
            content: '';
            position: absolute;
            top: 1.25rem;
            right: 0;
            background-color: $main;
            width: 2.8rem;
            height: 5px;
            transform: rotate(-45deg);
        }
    }
}

@media (max-width: 430px) {
    .popup {
        &__img {
            max-width: 90vw;
        }
    }
}