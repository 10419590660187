#snsads {
    background-color: $gray;
    padding-bottom: 6.5rem;

    .heading {
        text-align: center;
        margin-bottom: 4rem;
    }

    .card {
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;

        &__left {
            margin-right: 5vw;
        }

        &__text {
            font-weight: 500;
        }

        &__box {
            background-color: $black;
            padding: 2rem;
        }

        &__graph {
            text-align: center;
            font-size: 1.25rem;
            font-weight: 500;
        }
    }
}

@media (max-width: 430px) {
    #snsads {
        padding-top: 6rem;

        .heading {
            &__english {
                display: block;
                line-height: 1;
                margin-bottom: -2rem;
            }
        }

    
        .card {
            grid-template-columns: 1fr;
            gap: 1rem;
    
            &__left {
                margin-right: 0;
                order: 2;
            }
    
            &__text {
                font-size: 1.125rem;
            }
    
            &__box {
                background-color: $black;
                padding: 1.5rem 1rem;
                order: 1;
            }
    
            &__graph {
                font-size: 0.75rem;
            }
        }
    }
}