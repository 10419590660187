#about {
    padding-top: 8rem;

    .card {
        margin-bottom: 6rem;

        &__heading {
            font-size: 2.6rem;
            font-weight: 600;
        }

        &__grid {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 5rem;
        }

        &__img {
            padding-right: 4rem;
        }

        &__flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__text {
            font-size: 1.125rem;
            padding-left: 3rem;
            width: 50%;
        }

        &__box {
            width: 50%;
            display: grid;
            place-items: center;
        }
    }
}

@media (max-width: 430px) {
    #about {
        padding: 5rem 0 2rem 0;

        .card {
            margin-bottom: 4rem;

            &__heading {
                font-size: 1.75rem;
            }
    
            &__grid {
                grid-template-columns: 1fr;
                gap: 3rem;
            }
    
            &__img {
                padding-right: 0;
                transform: translateX(-3vw) scale(1.1);
            }
    
            &__flex {
                flex-direction: column;
                gap: 2rem;
            }
    
            &__text {
                font-size: 1.125rem;
                padding-left: 0;
                width: 100%;
                order: 2;
                font-weight: 500;
            }
    
            &__box {
                width: 100%;
                order: 1;
            }
        }
    }
}