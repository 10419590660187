#thanks {
    width: 100vw;
    height: 100vh;

    header {
        padding: .95rem 0;
    }

    .thanks {
        &__box {
            height: 100vh;
            text-align: center;
            display: grid;
            place-content: center;
            place-items: center;
            gap: 2rem;
            transform: translateY(-3rem);
        }

        &__link {
            font-size: 1.25rem;
            font-weight: 600;
            color: $main;
            background-color: $white;
            padding: 1rem 10rem;
            border: 3px solid $white;
            border-radius: 100px;

            &:hover {
                border: 3px solid $main;
            }
        }
    }

    footer {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
    }
}

@media (max-width: 430px) {
    #thanks {
        .thanks {
            &__box {
                width: 100%;
                gap: 1.5rem;
            }
            
            &__text {
                font-size: .875rem;
            }

            &__link {
                width: 100%;
                padding: 1rem 0;
                font-size: 1.125rem;
            }
        }
    }
}