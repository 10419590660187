li {
    list-style-type: '';
}

a {
    color: $white;
    text-decoration: none;
    transition: .3s;
}

.bold {
    font-weight: 600;
}

.line {
    background: linear-gradient(transparent 70%, #a2223790 70%);
}

.bg--red {
    background-color: $main;
    padding: 0 .8rem;
}

.bg--blue {
    background-color: $sub01;
}

.heading {
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 700;

    &__english {
        color: $main;
        font-size: 2.3rem;
        font-family: $bebas;
        letter-spacing: .3rem;
        font-weight: normal;
    }
}

.subheading {
    border-left: 10px solid $main;
    padding-left: 1rem;
    font-size: 2.25rem;
    font-weight: 600;
    margin-bottom: 2.5rem;
}

.pc-none {
    display: none;
}

.sp-br {
    display: none;
}

@media (max-width: 430px) {
    .heading {
        font-size: 1.625rem;
        text-align: center;
    
        &__english {
            font-size: 1.5rem;
        }
    }

    .subheading {
        border-left: 5px solid $main;
        padding-left: .7rem;
        font-size: 1.375rem;
        margin-bottom: 1.5rem;
    }

    .pc-none {
        display: block;
    }

    .sp-br {
        display: block;
    }

    .sp-none {
        display: none;
    }
}