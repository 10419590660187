html {
    scroll-padding-top: 4rem;
    scroll-behavior: smooth;
    height:100%;
}

body {
    font-family: $noto;
    font-optical-sizing: auto;
    font-weight: 400;
    color: $white;
    background-color: $black;
    height:100%;
    text-align: justify;
    line-height: 1.6;
}

img, video {
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

svg {
    fill: $white;
    transition: .3s;
}

section {
    padding: 5rem 0;
}

.wrapper {
    max-width: 1170px;
    margin: 0 auto;
}

.triangle {
    background-color: $sub02;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    transition: .3s;
}

.box {
    border-radius: 10px;
    background-color: $gray;
}

@media (max-width: 430px) {
    html {
        scroll-padding-top: 0;
    }

    .wrapper {
        max-width: 90vw;
    }
}