header {
    position: fixed;
    z-index: 16;
    width: 100vw;
    background-color: $main;

    .wrapper {
        max-width: 100vw;
        display: flex;
        justify-content: space-between;

        .title {
            display: flex;
            align-items: center;
            margin-left: 5vw;
    
            &__logo {
                width: 4rem;
            }
    
            &__text {
                font-size: .75rem;
                font-weight: 400;
                margin-left: .5rem;
            }
    
            &__triangle {
                position: absolute;
            }
        }
    
        .gnav {
            display: flex;
    
            &__ul {
                display: flex;
                align-items: center;
                gap: 1.5rem;
                margin-right: 2rem;
                font-weight: 500;
            }
    
            &__li {
                position: relative;
                display: inline-block;

                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                    bottom: -.8rem;
                    width: 10px;
                    height: 10px;
                    border-top: 2px solid $white;
                    border-right: 2px solid $white;
                    transform: rotate(135deg);
                    transition: .3s;
                }

                &:hover {
                    a {
                        opacity: .5;
                    }

                    &::before {
                        opacity: .5;
                    }
                }
            }
    
            &__btn {
                position: relative;
                background-color: $sub01;
                aspect-ratio: 1 / 1;
                display: grid;
                place-items: center;
                padding: .5rem;

                &:hover {
                    color: #ffffff7e;
                    background-color: #0e182f;

                    & .triangle {
                        background-color: #263352;
                    }

                    & .gnav__contact--normal::after {
                        border-top: 2px solid #ffffff7e;
                        border-right: 2px solid #ffffff7e;
                    }
                }
            }
    
            &__contact {
                position: sticky;
                z-index: 1;
                text-align: center;
                font-weight: 400;
                line-height: 1.4;
    
                &--big {
                    font-size: 1.2rem;
                    font-weight: 500;
                }
    
                &--normal {
                    display: inline-block;
                    transform: translateX(-.5rem);
                    position: relative;
                }
    
                &--normal::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    right: -.8rem;
                    width: 10px;
                    height: 10px;
                    border-top: 2px solid $white;
                    border-right: 2px solid $white;
                    transform: rotate(45deg) translateY(.1rem);
                    transition: .3s;
                }
            }
    
            &__triangle {
                position: absolute;
            }
        }
    }
}

.hero {
    padding-top: 5rem;
    width: 100%;
    height: auto;
    overflow: hidden;

    &__movie {
        object-fit: cover;
        object-position: center center;
    }

    .copy {
        font-size: 1.75rem;
        text-align: center;
        line-height: 1.8;
        margin-top: 6.5rem;

        &__big {
            font-size: 3.125rem;

            .bg--red {
                margin-right: .5rem;
            }
        }
    }
}

@media (max-width: 430px) {
    header {
        .wrapper {
            max-width: 100vw;
            .title {
                &__logo {
                    width: 12vw;
                    margin-left: 5vw;
                }

                &__text {
                    font-size: .625rem;
                }
            }

            .gnav {
                &__ul {
                    display: none;
                }

                &__btn {
                    transition: .3s;

                    &.close {
                        opacity: 0;
                    }
                }
        
                &__contact {
                    line-height: 1;
                    min-width: 0;
        
                    &--big {
                        font-size: .75rem;
                        font-weight: 700;
                    }
        
                    &--normal {
                        transform: translateX(-.3rem);
                        font-size: .625rem;
                        font-weight: 600;
                    }
        
                    &--normal::after {
                        right: -.5rem;
                        width: 6px;
                        height: 6px;
                        border-top: 1px solid $white;
                        border-right: 1px solid $white;
                        transform: rotate(45deg) translateY(0);
                    }
                }

                &__ham {
                    background-color: $white;
                    aspect-ratio: 1 / 1;
                    width: 4rem;
                    display: grid;
                    place-items: center;

                    span {
                        position: relative;
                        display: inline-block;
                        width: 2rem;
                        height: 4px;
                        border-radius: 10px;
                        background-color: $main;
                        vertical-align: middle;
                        transition: .3s;
                    }

                    span::before, span::after {
                        content: '';
                        position: absolute;
                        width: 2rem;
                        height: 4px;
                        border-radius: 10px;
                        background-color: $main;
                        transition: .3s;
                    }

                    span::before {
                        top: -.6rem;
                        left: 0;
                    }

                    span::after {
                        bottom: -.6rem;
                        left: 0;
                    }

                    &.active {
                        background-color: $main;
                    }

                    &.active span::before, &.active span::after {
                        background-color: $white;
                    }

                    &.active span::before {
                        top: 0;
                        transform: rotate(-45deg);
                    }

                    &.active span::after {
                        bottom: 0;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .ham {
        width: 100vw;
        height: 100vh;
        background-color: #ffffff00;
        backdrop-filter: blur(5px);
        position: fixed;
        z-index: -10;
        font-size: 1.25rem;
        font-weight: 400;
        transition: .3s;
        opacity: 0;


        &.open {
            opacity: 1;
            z-index: 15;
        }

        &__bg {
            width: 100%;
            height: 70vh;
            background-color: $black;
            transform: translateY(-100vh);
            transition: .3s;

            &.open {
                transform: translateY(0);
            }

            .wrapper {
                max-width: 80vw;
                height: 100%;
            }
        }

        &__ul {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1.5rem;
        }

        &__li {
            font-weight: 600;
            position: relative;
            display: block;
        }

        &__li::after {
            content: '';
            position: absolute;
            display: inline-block;
            top: 50%;
            right: 0;
            width: 10px;
            height: 10px;
            border-top: 2px solid $white;
            border-right: 2px solid $white;
            transform: rotate(45deg) translateY(-50%);
        }
    }

    .hero {
        padding-top: 4rem;
    
        &__movie {
            object-fit: cover;
            object-position: center center;
        }

        .copy {
            font-size: 1.5rem;
            text-align: center;
            margin: 4rem 0 0 0;
            line-height: 1.4;
    
            &__big {
                display: block;
                font-size: 2rem;
                margin-top: .3rem;
    
                .bg--red {
                    margin-right: 0;
                }
            }
        }
    }
}