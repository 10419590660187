#merit {
    .heading {
        text-align: center;
        margin-bottom: 3.5rem;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 80%;
        margin: 0 auto;
    }

    .card {
        &__circle {
            background-color: $main;
            border-radius: 176px;
            width: 176px;
            height: 176px;
            display: grid;
            place-items: center;
            margin: 0 auto;
        }

        &__title {
            font-size: 1.25rem;
            text-align: center;
            margin-top: 1rem;
        }

        &__img {
            height: 100px;
        }
    }

    section + section {
        padding-top: 1.5rem;
    }

    #point {
        .point {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: .5rem;

            &__box {
                padding: 1.8rem 4rem 2.2rem 4rem;
            }
        }

        .pointheading {
            font-size: 1.25rem;
            line-height: 1.2;
            text-align: center;
            margin-bottom: 1.5rem;

            &__english {
                color: $main;
                font-family: $bebas;
                font-size: 2.25rem;
                font-weight: normal;
            }
        }
    }

    #influence {
        .grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 1rem;
            width: 100%;

            .box {
                text-align: center;

                &__img {
                    width: 30%;
                    margin-top: 3.5rem;

                    &--arrow {
                        width: 40%;
                        margin-top: 2rem;
                    }

                    &--tiktok {
                        width: 25%;
                        margin-top: 3.5rem;
                    }
                }

                &__title {
                    font-size: 1.25rem;
                    margin-top: 2rem;
                    font-weight: 500;

                    &--big {
                        font-family: $bebas;
                        font-size: 6.25rem;
                        font-weight: normal;
                    }

                    &--medium {
                        font-size: 2rem;
                    }

                    .line {
                        background: linear-gradient(transparent 35%, #a2223790 35%)
                    }
                }
            }
        }
    }

    #menu {
        .menu {
            &__img {
                aspect-ratio: 1 / 1;
                border-radius: 20px;
                overflow: hidden;
                cursor: pointer;
            }

            &__title {
                font-size: 1.25rem;
                font-weight: 500;
                margin: 1rem 0 .5rem 0;
            }
    
            &__text {
                font-size: .875rem;
            }
        }
    }
}

@media (max-width: 430px) {
    #merit {
        .grid {
            grid-template-columns: 1fr 1fr;
            width: 100%;
            gap: 1.5rem 0;
        }
    
        .card {
            &__circle {
                width: 38vw;
                height: 38vw;
            }
    
            &__title {
                font-size: 0.75rem;
                text-align: center;
                margin-top: 1rem;
            }
    
            &__img {
                height: 80px;
            }
        }
    
        section + section {
            padding-top: 0;
        }
    
        #point {
            .point {
                grid-template-columns: 1fr;
                gap: .5rem;
    
                &__box {
                    padding: 1.5rem;
                    font-size: .875rem;
                }
            }
    
            .pointheading {
                font-size: 1rem;
                line-height: 1.2;
                text-align: left;
                margin-bottom: .5rem;
    
                &__english {
                    font-size: 1.875rem;
                    margin-right: .8rem;
                }
            }
        }
    
        #influence {
            .grid {
                grid-template-columns: 1fr 1fr;
                gap: .5rem;
                width: 100%;
    
                .box {
                    text-align: center;
                    padding-bottom: .5rem;
    
                    &__img {
                        width: 23%;
                        margin-top: 2rem;

                        &--arrow {
                            width: 35%;
                            margin-top: 1rem;
                        }
    
                        &--tiktok {
                            width: 20%;
                            margin-top: 2rem;
                        }
                    }
    
                    &__title {
                        font-size: 0.75rem;
                        margin-top: 1rem;
    
                        &--big {
                            font-size: 2.5rem;
                        }
    
                        &--medium {
                            font-size: 1rem;
                        }
    
                        .line {
                            background: linear-gradient(transparent 40%, #a2223790 40%)
                        }
                    }
                }
            }
        }
    }
}