footer {
    text-align: center;
    background-color: $gray;
    padding-bottom: 1rem;

    .contact {
        position: relative;
        background-color: $sub01;
        padding: 2.5rem 0 2.8rem 0;

        &__heading, &__text, &__btn {
            position: sticky;
            z-index: 1;
        }

        &__heading {
            font-size: 2.25rem;
            margin-bottom: 1rem;
            font-weight: 600;
        }

        &__text {
            font-size: 0.9375rem;
            font-weight: 500;
        }

        &__btn {
            display: block;
            color: $sub01;
            background-color: $white;
            border: 1px solid $white;
            border-radius: 100px;
            font-size: 1.25rem;
            font-weight: 600;
            width: 32rem;
            border: 3px solid $white;
            box-shadow: 3px 7px 15px 0px #00000081;
            padding: 1.2rem 0;
            margin: 1.5rem auto 0 auto;

            &:hover {
                border: 3px solid $sub01;
            }
        }

        &__triangle {
            position: absolute;
        }
    }

    .sns {
        .wrapper {
            display: flex;
            justify-content: center;
            gap: 8px;
            padding: 4rem 0 2rem 0;
        }

        &__btn {
            background-color: $main;
            border: 3px solid $main;
            border-radius: 100px;
            width: 50px;
            height: 50px;
            display: grid;
            place-items: center;

            &:hover {
                background-color: $white;

                svg {
                    fill: $main;
                }
            }
        }
    }

    small {
        font-size: .75rem;
    }
}

@media (max-width: 430px) {
    footer {
        padding-bottom: 1.5rem;

        .contact {
            padding: 3rem 0 3.5rem 0;

            &__heading {
                font-size: 1.75rem;
            }

            &__text {
                text-align: justify;
            }

            &__btn {
                font-size: 1rem;
                width: 100%;
                padding: .8rem 0;
            }
        }

        .sns {
            .wrapper {
                padding: 2rem 0 1rem 0;
            }
        }
    }
}